 import api from './api'
 class Endpoint {
  getAllNavMenu(){
    return api.get('/public/nav_menu/all')
  }
  getAllHomeContent(){
    return api.get('/public/home_content/all')
  }
  getYoutubeLatestStream(api_key){
    return api.get('/public/home/youtube?api_key='+api_key)
  }
  getPendeta(){
    return api.get('/public/pendeta/list')
  }
  getPelkatBySlug(slug){
    return api.get('/public/pelkat/'+slug)
  }
  getKomisiBySlug(slug){
    return api.get('/public/komisi/'+slug)
  }
  getPresbiterBySlug(slug){
    return api.get('/public/presbiter/'+slug)
  }
  getKmjList(){
    return api.get('/public/kmj/list')
  }
  getPhmjList(){
    return api.get('/public/phmj/list')
  }
  // Some Datas Bulked!
  // Berita Duka
  getAllBeritaDuka(page){
    return api.get('/public/berita_duka/list?page='+page)
  }
  getCurrentBeritaDuka(){
    return api.get('/public/berita_duka/current')
  }
  getBeritaDukaById(id){
    return api.get('/public/berita_duka/detail/'+id)
  }
  // Warta Jemaat
  getAllWartaJemaat(page){
    return api.get('/public/warta_jemaat/list?page='+page)
  }
  getWartaById(id){
    return api.get('/public/warta_jemaat/detail/'+id)
  }
  // TataIbadahminggu
  getAllTataIbadahMinggu(page){
    return api.get('/public/ti_minggu/list?page='+page)
  }
  getTataIbadahMingguById(id){
    return api.get('/public/ti_minggu/detail/'+id)
  }
  // TataIbadahIka
  getAllTataIbadahIka(page){
    return api.get('/public/ti_ika/list?page='+page)
  }
  getTataIbadahIkaById(id){
    return api.get('/public/ti_ika/detail/'+id)
  }
  // End - Some Datas Bulked!
  // Iuran Makam
  getIuranMakamByKode(api_key,kode){
    return api.get('/public/iuran_makam/kod_fam/?api_key='+api_key+"&kode_keluarga="+kode)
  }
  getIuranMakamByNama(api_key,nama){
    return api.get('/public/iuran_makam/nam_fam/?api_key='+api_key+"&nama_keluarga="+nama)
  }
  // ABSEN GP
  getSektors(api_key){
    return api.get('/public/absen_gp/sektors/?api_key='+api_key)
  }
  getAbsenAvailability(api_key){
    return api.get('/public/absen_gp/check_availability/?api_key='+api_key)
  }
  postAbsenGp(data){
    return api.post('/public/absen_gp/absen',data)
  }
 }
 export default new Endpoint()
 