<template>
  <transition-group name="scale">
    <router-view />
  </transition-group>
</template>

<script>
export default {
}
</script>

<style>
body {
  font-family: 'Roboto', sans-serif;
}

/* .footer{background-color: rgb(3, 5, 50); color: white;background-size: cover;padding-top:50px;padding-bottom:50px;} */
/* Footer */
footer {
  color: white;
  background-size: cover;
  /* background-color: #343a40 !important; */
  background-color: black !important;
  padding-top: 50px;
  padding-bottom: 50px;
}

.footer-uhuy {
  padding: 1rem;
}

.footer-title {
  font-size: 20px;
  color: white;
}

.social-caption {
  font-size: 14px;
}

.bg-ihm{
  background-image: url("/src/assets/ihm2.jpg");
  display: block;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 22vh;
}
.bg-ika{
  background-image: url("/src/assets/ika2.jpg");
  display: block;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 22vh;
}
.bg-wj{
  background-image: url("/src/assets/wj-cover2.jpg");
  display: block;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 22vh;
}
.bg-kalem {
  background: #212529 !important;
}

.about-us {
  list-style-type: none;
  text-decoration: none;
  color: white;
  line-height: 2;
  font-size: 1.1rem;
}
.card-hover {
    transition: 0.4s;
}
.card-hover:hover {
    transform: scale(1.03);
    transition: 0.4s;
}
iframe, object, embed {
    max-width: 100%;
    max-height: 100%;
}

.zoom {
  transition: .2s;
}

.zoom img {
  transition: .2s;
}

.zoom:hover img {
  transform: scale(1.05);
}

img.banner-top {
  width: 45%;
}

img.p-img-show {
  display: none;
}

a.nav-link {
  padding: 1rem;
}

a.nav-link.active {
  border-bottom: solid 3px white;
}

nav.navbar {
  padding: 0 1rem;
}

/* span for navbar */
span.link-text {
  display: inline-block;
  text-transform: uppercase;
  cursor: pointer;
  color: white;
}

span.link-text:after {
  display: block;
  content: '';
  border-bottom: solid 3px white;
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
}

span.link-text:hover:after {
  transform: scaleX(1);
}

/* span for others */
span.link-text2 {
  display: inline-block;
  text-transform: uppercase;
  cursor: pointer;
}

span.link-text2:after {
  display: block;
  content: '';
  /* border-bottom: solid 3px green; */
  border-bottom: solid 3px black;
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
}

span.link-text2:hover:after {
  transform: scaleX(1);
}

/*
  Enter and leave animations can use different
  durations and timing functions.
*/
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}
.v-enter-from,
.v-leave-to {
  opacity: 0;
}
#badge-blink {
  animation: blinker 1.3s linear infinite;
}
@keyframes blinker {
  50% {
    opacity: 0;
  }
}
.slide-enter-active,
.slide-leave-active {
  transition: all 1s ease-out;
}
.slide-enter-to {
  position: absolute;
  right: 0;
}

.slide-enter-from {
  position: absolute;
  right: -100%;
}

.slide-leave-to {
  position: absolute;
  left: -100%;
}

.slide-leave-from {
  position: absolute;
  left: 0;
}
.scale-enter-active,
.scale-leave-active {
  transition: all 1s ease;
}
.scale-enter-from,
.scale-leave-to {
  opacity: 0;
  transform: scale(0.9);
}
.scale-slide-enter-active,
.scale-slide-leave-active {
  position: absolute;
  transition: all 1s ease;
}

.scale-slide-enter-from {
  left: -100%;
}

.scale-slide-enter-to {
  left: 0%;
}

.scale-slide-leave-from {
  transform: scale(1);
}

.scale-slide-leave-to {
  transform: scale(0.9);
}
.slide-fade-enter-active {
  transition: all 0.4s ease-out;
}
.slide-fade-leave-active {
  transition: all 0.4s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
.slide-left-enter,
.slide-right-leave-active {
  opacity: 0;
  -webkit-transform: translate(30px, 0);
  transform: translate(30px, 0);
}
.slide-left-leave-active,
.slide-right-enter {
  opacity: 0;
  -webkit-transform: translate(-30px, 0);
  transform: translate(-30px, 0);
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.7s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.bounce-enter-active {
  animation: bounce-in 0.7s;
}
.bounce-leave-active {
  animation: bounce-in 0.7s reverse;
}

@media only screen and (max-width: 600px) {
  .p-show {
    display: none;
  }

  img.p-img-show {
    display: block !important;
    width: 2rem;
  }

  img.banner-top {
    width: 100%;
  }

  a.nav-link.active {
    background-color: inherit;
    font-weight: bolder;
  }
}</style>
