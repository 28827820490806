import api from './api'
import TokenService from './token.service'
class AuthService {
  // Login
  async login({ username, password}) {
    const response = await api.post('/user/login', {
      username,
      password,
    })
    if (response.data.data.token) {
      TokenService.setUser(response.data)
    }
    return response.data
  }
  // Logout
  // async logout() {
  //   return api.post('logout')
  // }
}
export default new AuthService()
