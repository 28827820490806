import { createStore } from 'vuex'
import { auth } from './auth.module'
import Endpoint from "../services/endpoints";

const store = createStore({
  state: {
    app_detail:{},
    apps_by_category:[],
    category_name:null

  },
  mutations: {
    GET_APP_BY_CAT(state,payload){
      state.apps_by_category = payload
    },
    SET_APP_CAT(state,payload){
      state.category_name = payload
    },
    SET_APP_DETAIL(state,payload){
      state.app_detail = payload
    }
  },
  actions: {
    getDetailApps({ commit }, payload) {
      return new Promise((resolve) => {
          Endpoint.getAppsDetail(payload).then((r=>{
            commit('SET_APP_DETAIL', r.data.data)
            resolve()
          }))

      })
    },
    getAppsByCat({ commit }, payload) {
      return new Promise((resolve) => {
          Endpoint.getAppsByCategoryId(payload).then((r=>{
            if (payload == 1){
              commit('SET_APP_CAT', 'Production')
            } else if (payload == 2){
              commit('SET_APP_CAT', 'Development - Closed Beta')
            } else if (payload == 3){
              commit('SET_APP_CAT', 'Development - Open Beta')
            }
            commit('GET_APP_BY_CAT', r.data.data)
            resolve()
          }))

      })
    },
  },
  modules: {
    auth,
  },
})

export default store
