<template>
  <div style="background-color: #343a40;">
    <header_client />
      <div class="container-fluid min-vh-100">
          <transition-group name="slide-fade">
            <router-view />
          </transition-group>
      </div>
    <footer_client />
  </div>
  </template>
  <script>

  import header_client from '@/components/header_client.vue'
  import footer_client from '@/components/footer_client.vue'
 
  export default {
    name: 'layout_client',
    components: {
      header_client,
      footer_client,
    },
  }
  </script>